.circle {
    color: blue;
    width: 200px;
    height: 200px;
    background-color: red;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.active {
    background-color: antiquewhite;
}
