.card {
    width: 25%;
    height: 400px;
    background-color: #f4a261;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
        rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}
.card img {
    overflow: hidden;
}
.quit {
    position: absolute;
    right: 5px;
    top: 5px;
    color: #e76f51;
    font-size: 20px;
    transition: all 0.3s;
    cursor: pointer;
    backdrop-filter: invert(80%);
    border-radius: 100%;
    width: 30px;
    height: 30px;
}
.quit:hover {
    backdrop-filter: invert(1%);
    color: red;
    scale: 150%;
}
.details {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.like {
    cursor: pointer;
    transition: all 0.3s;
}

.like:hover {
    transform: scale(50%);
    color: #e76f51;
}

