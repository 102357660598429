
.paper {
    width: 100%;
    /* height: 100%; */
    min-height: 10vh;
    /* padding: 35px 20px; */
    background: repeating-linear-gradient(#f1ede9, #f1ede9 31px, #94acd4 31px, #94acd4 32px);
    font-family: "Shadows Into Light", cursive;
    line-height: 32px;
    outline: 0;
    font-size: 22px;
}
